<template lang="pug">
v-dialog(v-model="modal",absolute,fullscreen,transition="slide-x-transition")
  div.expande-horizontal.centraliza.fonte.vitro(style="min-height: 100vh;").pa-2
    v-flex(xs12 md6)
      v-card.pa-6.pt-12.pb-12
        //- Em caso de sucesso na compra 
        div.expande-horizontal.wrap(v-if="success")
          v-flex(xs12).mb-6
            div.expande-horizontal.centraliza
              v-icon(color="green", size="70") mdi-check-circle-outline
          v-flex(xs12).pb-4
            div.expande-horizontal.centraliza
              span.text-center(style="font-size: 27px;") Parabéns!
          v-flex(xs12).pb-12(v-if="result.method === 'card' && get_checkout.trial")
            div.expande-horizontal.centraliza
              span.text-center Sua assinatura está prontinha, basta clicar no link que enviamos no seu email para configurar sua conta e aproveitar seus 7 dias totalmente grátis!
          v-flex(xs12).pb-12(v-if="result.method === 'card' && !get_checkout.trial")
            div.expande-horizontal.centraliza
              span.text-center Sua assinatura está prontinha, basta clicar no link que enviamos no seu email para configurar sua conta!
          v-flex(xs12).pb-12(v-if="result.method === 'bank_slip' && get_checkout.trial")
            div.expande-horizontal.centraliza.column
              span.text-center Sua assinatura está prontinha, basta clicar no link que enviamos no seu email para configurar sua conta e aproveitar seus 7 dias totalmente grátis!
          v-flex(xs12).pb-12(v-if="result.method === 'bank_slip' && !get_checkout.trial")
            div.expande-horizontal.centraliza.column
              span.text-center Seu boleto foi gerado com sucesso, enviaremos também por email, assim que detectarmos o pagamento, o link para configurar sua conta será enviado no seu email!
              v-btn.mt-6(:color="$theme.secondary" @click="goToBankSlip" dark v-if="result.external_data.pdf") Clique aqui para acessar seu boleto!
          v-flex(xs12)
            div.expande-horizontal.centraliza.column
              span.text-center A Equipe SysRocket
              span.text-center Agradece a sua preferência!
        div.expande-horizontal.wrap(v-if="error")
          v-flex(xs12).mb-6
            div.expande-horizontal.centraliza
              v-icon(color="orange", size="70") mdi-close-circle-outline
          v-flex(xs12).pb-4
            div.expande-horizontal.centraliza
              span.text-center(style="font-size: 27px;") Opa!
          v-flex(xs12).pb-12
            div.expande-horizontal.centraliza
              span.text-center Ocorreu um erro com sua compra, veja mais detalhes sobre o problema abaixo e depois clique no botão para revisar e tentar novamente!
          v-flex(xs12).pb-12
            div.expande-horizontal.centraliza.column
              span.text-center.font-weight-bold Problema: 
              span.text-center {{ error_message }}
          v-flex(xs12)
            div.expande-horizontal.centraliza.column
              v-btn(:color="$theme.secondary" dark rounded @click="tryAgain") Revisare e tentar novamente!

        //- span {{ result }}
        //- span.red--text Erro: {{ error }}

</template>

<script>
import { mapGetters } from "vuex";
import { EventBus } from "@/main.js";
export default {
  data() {
    return {
      modal: false,
      error: false,
      error_message: "",
      result: {
        external_data: {
          success: false
        }
      }
    };
  },
  computed: {
    ...mapGetters(["get_checkout"]),
    success() {
      if (
        this.result &&
        this.result.external_data &&
        this.result.external_data.success
      ) {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    goToBankSlip() {
      window.open(this.result.external_data.pdf, "_blank");
    },
    tryAgain() {
      this.modal = false;
      this.error = false;
      this.error_message = "";
      this.result = {
        external_data: {
          success: false
        }
      };
    }
  },
  created() {
    EventBus.$on("modal-order-status", result => {
      this.result = result.charge;
      this.modal = true;
    });
    EventBus.$on("checkout-success", result => {
      this.result = result.charge;
      this.modal = true;
    });
    EventBus.$on("checkout-error", error => {
      this.modal = true;
      this.error = true;
      this.error_message = error;
    });
  }
};
</script>

<style scoped>
.checkout-methods-container {
  background: #f0f0f0;
  border: 1px solid #3cacba;
  border-radius: 20px;
  padding: 24px;
}
</style>
