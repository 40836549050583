<template lang="pug">
v-flex(xs12,md6,:class="{'pl-3': $vuetify.breakpoint.lgAndUp}").pt-12.fonte
  div.expande-horizontal.centraliza.wrap
    v-flex(xs12).mb-3
      div.expande-horizontal.centraliza.wrap
        v-flex(xs12).mb-3
          span.checkout-paymentmethod-font.fonte Selecione o método de pagamento: 
      div.expande-horizontal.centraliza.wrap.checkout-paymentmethod-container.pa-6.pt-12.pb-12
        v-flex.pb-1(@click="selectMethod('card')",xs7)
          div.clickable.checkout-card-method(:class="{ 'checkout-card-method-selected': get_checkout.method === 'card' }")
            img(src="img/checkout/checkout-card.svg")
            span.fonte Cartão de Crédito
        v-flex.pb-1(@click="selectMethod('bank_slip')",xs7)
          div.clickable.checkout-card-method(:class="{ 'checkout-card-method-selected': get_checkout.method === 'bank_slip' }")
            img(src="img/checkout/checkout-boleto.svg")
            span.fonte Boleto
        //- v-flex(@click="selectMethod('pix')",xs7)
        //-   div.clickable.checkout-card-method(:class="{ 'checkout-card-method-selected': get_checkout.method === 'pix' }")
        //-     img(src="img/checkout/checkout-pix.svg")
        //-     span.fonte Pix

</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["get_checkout"])
  },
  methods: {
    selectMethod(method) {
      this.get_checkout.method = method;
      this.$vuetify.goTo(2000);
      this.$forceUpdate();
    }
  }
};
</script>

<style>
.checkout-paymentmethod-container {
  background: #f0f0f0;
  border: 1px solid #3cacba;
  border-radius: 20px;
}
.checkout-paymentmethod-font {
  font-weight: 600;
  font-size: 18px;
  line-height: 39px;
  color: #363636;
}
.checkout-card-method {
  padding: 6px;
  width: 100%;
  margin-bottom: 24px;
  background: #f8f8f8;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.checkout-card-method-selected {
  border: 2px solid #3cacba;
}

.checkout-card-method img {
  height: 60px;
}

.checkout-card-method span {
  font-size: 12px;
}
.clickable {
  cursor: pointer;
}
</style>
