<template lang="pug">
v-flex(xs12,md6,:class="{ 'pr-3': $vuetify.breakpoint.lgAndUp }").pt-12
  div.expande-horizontal.centraliza.wrap
    v-flex(xs12).mb-3
      div.expande-horizontal.centraliza.wrap
        v-flex(xs12).mb-3
          span.checkout-informations-font.fonte Informações do cliente:
      div.expande-horizontal.wrap.checkout-informations-container.pa-6.pt-12
        v-flex(xs12)
          v-form
            div.expande-horizontal.centraliza.wrap
              v-flex(xs10)
                v-text-field(
                  dense
                  outlined 
                  label="Nome completo"
                  v-model="get_checkout.payer.name"
                  placeholder="Nome completo"
                  :color="$theme.secondary"
                )
              v-flex(xs10)
                v-text-field(
                  dense
                  outlined 
                  label="E-mail"
                  v-model="get_checkout.payer.email"
                  placeholder="Insira seu melhor E-mail"
                  :color="$theme.secondary"
                )
              //- v-flex(xs10)
              //-   v-text-field(
              //-     dense
              //-     outlined 
              //-     label="Confirmar e-mail"
              //-     v-model="get_checkout.payer.confirm_email"
              //-     placeholder="Confirme seu E-mail"
              //-     :color="$theme.secondary"
              //-   )
              v-flex(xs10)
                v-text-field(
                  dense
                  outlined 
                  label="CPF ou CNPJ"
                  v-model="get_checkout.payer.cpf_cnpj"
                  placeholder="Insira seu CPF ou CNPJ"
                  :color="$theme.secondary"
                )
              v-flex(xs10)
                div.expande-horizontal
                  v-flex(xs3).pr-2
                    v-text-field(
                      dense
                      outlined 
                      label="DDD"
                      v-model="get_checkout.payer.phone_prefix"
                      placeholder="ex: 96"
                      :color="$theme.secondary"
                      v-mask=['###']
                    )
                  v-flex(xs9)
                    v-text-field(
                      dense
                      outlined 
                      label="Telefone"
                      v-mask="['# #### ####']"
                      v-model="get_checkout.payer.phone"
                      placeholder="Insira seu telefone"
                      :color="$theme.secondary"
                    )
          

</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["get_checkout"])
  }
};
</script>

<style>
.checkout-informations-container {
  background: #f0f0f0;
  border: 1px solid #3cacba;
  border-radius: 20px;
}
.checkout-informations-font {
  font-weight: 600;
  font-size: 18px;
  line-height: 39px;
  color: #363636;
}
</style>
