<template lang="pug">
v-flex(xs12).pb-12
  div.expande-horizontal.centraliza.wrap
    v-flex(xs12 md8)
      div.expande-horizontal.checkout-methods-container
        CardMethod(v-if="get_checkout.method === 'card'")
        BankSlipMethod(v-if="get_checkout.method === 'bank_slip'")
        //- PixMethod(v-if="get_checkout.method === 'pix'")
</template>

<script>
import { mapGetters } from "vuex";
import CardMethod from "./methods/Card.vue";
import BankSlipMethod from "./methods/BankSlip.vue";
import PixMethod from "./methods/Pix.vue";
export default {
  components: {
    CardMethod,
    BankSlipMethod,
    PixMethod
  },
  computed: {
    ...mapGetters(["get_checkout"])
  }
};
</script>

<style scoped>
.checkout-methods-container {
  background: #f0f0f0;
  border: 1px solid #3cacba;
  border-radius: 20px;
  padding: 24px;
}
</style>
