<template lang="pug">
v-flex(xs12).pt-12
  div.expande-horizontal.centraliza.wrap
    v-flex(xs12 md8).mb-3
      span.fonte.checkout-items-font Resumo do pedido
    v-flex(xs12 md8)
      div.expande-horizontal.checkout-items-border.wrap
        v-flex(xs12)
          div.expande-horizontal.wrap
            v-flex(xs12 md9)
              span.checkout-items-table-font.fonte Nome do plano
            v-flex.pr-1(xs12 md3 v-if="$vuetify.breakpoint.smAndUp")
              span.checkout-items-table-font.fonte Preço
            //- v-flex(xs2)
            //-   span.checkout-items-table-font.fonte Ações
      v-divider 
      div.expande-horizontal.checkout-items-table-body
        v-flex(xs12)
          div.expande-horizontal
            v-flex(xs12 md9)
              div.expande-horizontal
                div.checkout-items-avatar
                  img.checkout-items-avatar-img(src="img/logo_mini.svg")
                div.expande-horizontal.column.checkout-items-cell
                  span.fonte.checkout-item-title {{ get_checkout.plan._id ? get_checkout.plan.name : 'Carregando...' }}
                  span.fonte.checkout-item-subtitle Mensal
                  span.fonte.checkout-item-subtitle.mt-2.green--text(v-if="$vuetify.breakpoint.smAndDown") {{ $helper.formataSaldo(get_checkout.plan._id ? (get_checkout.plan.value_cents / 1000) : 0) }}
                  div(v-if="trial")
                    v-icon(color="yellow darken-2", size="16").mr-1 mdi-star
                    span.fonte.checkout-item-subtitle.orange--text.font-mini(style="font-size: 9pt;") 7 dias grátis inclusos
            v-flex(xs12 md3 v-if="$vuetify.breakpoint.lgAndUp")
              div.expande-horizontal
                span.fonte.checkout-item-title.pl-7.pt-7 {{ $helper.formataSaldo(get_checkout.plan._id ? (get_checkout.plan.value_cents / 1000) : 0) }}
            //- v-flex(xs2)
            //-   div.expande-horizontal
            //-     span.clickable.fonte.checkout-item-subtitle.red--text.pl-7.pt-8(@click="() => {}") cancelar
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      trial: this.$route.query.trial === "true" ? true : false
    };
  },
  computed: {
    ...mapGetters(["get_checkout"])
  }
};
</script>

<style scoped>
.checkout-items-font {
  font-weight: 600;
  font-size: 18px;
  line-height: 39px;
  color: #363636;
}
.checkout-items-border {
  background: #d4d4d4;
  border: 1px solid #010f50;
  border-radius: 20px 20px 0px 0px;
}
.checkout-items-table-body {
  background: #f0f0f0;
  border: 1px solid #3cacba;
  border-radius: 0px 0px 20px 20px;
  border-top: none;
  padding: 24px;
}
.checkout-items-avatar {
  min-height: 40px;
  padding: 6px;
  padding-right: 18px;
  padding-left: 18px;
  min-width: 80px;
  background: #fff;
  border: 1px solid #010f50;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.checkout-items-avatar-img {
  width: 30px;
}
.checkout-items-table-font {
  font-weight: 600;
  font-size: 16px;
  line-height: 39px;
  color: #363636;
  padding-left: 24px;
}
.checkout-items-cell {
  padding: 24px;
}
.checkout-item-title {
  font-weight: 500;
  font-size: 24px;
  color: #363636;
}
.checkout-item-subtitle {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 15px;
  color: #363636;
}
.clickable {
  cursor: pointer;
}
</style>
