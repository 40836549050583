<template lang="pug">
div.expande-horizontal.wrap
  CheckoutDesktop(:plan_slug="plan_slug")
  //- CheckoutDesktop(:plan_slug="plan_slug",v-if="$vuetify.breakpoint.lgAndUp")
  //- CheckoutMobile(:plan_slug="plan_slug",v-else)
</template>

<script>
import CheckoutDesktop from "../components/DesktopPublic/Index.vue";
import CheckoutMobile from "../components/MobilePublic/Index.vue";
export default {
  props: ["plan_slug"],
  components: {
    CheckoutDesktop,
    CheckoutMobile
  },
  data() {
    return {};
  },
  created() {
    if (this.$route.query.c) {
      localStorage.code_indication = this.$route.query.c;
    }
  }
};
</script>
