<template lang="pug">
div.expande-horizontal.wrap.pa-1
  checkout-header
  checkout-items
  div.expande-horizontal.centraliza.wrap
    v-flex(xs12 md8).mb-3
      div.expande-horizontal.wrap
        checkout-informations
        checkout-payment-method
  checkout-submit
  modal-order-status
</template>

<script>
import CheckoutHeader from "./CheckoutHeader.vue";
import CheckoutItems from "./CheckoutItems/index.vue";
import CheckoutInformations from "./CheckoutInformations/index.vue";
import CheckoutPaymentMethod from "./CheckoutPaymentMethod/index.vue";
import CheckoutSubmit from "./CheckoutSubmit/index.vue";
import ModalOrderStatus from "./ModalOrderStatus/index.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  props: ["plan_slug"],
  components: {
    CheckoutHeader,
    CheckoutItems,
    CheckoutInformations,
    CheckoutPaymentMethod,
    CheckoutSubmit,
    ModalOrderStatus
  },
  computed: {
    ...mapGetters(["get_checkout"])
  },
  methods: {
    ...mapActions(["view_plan", "check_transaction_payment"])
  },
  mounted() {
    if (this.plan_slug) {
      this.view_plan(this.plan_slug);
    }
    if (this.$route.query.t_id) {
      this.check_transaction_payment(this.$route.query.t_id);
    }
    if (this.$route.query.trial === "true") {
      this.get_checkout.trial = true;
    }
  }
};
</script>
